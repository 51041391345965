@import url('https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;600;700;800&display=swap');
html,body{
  font-family: 'Baloo Paaji 2' !important;
  background-color: #5d6465;
  overflow-x: hidden;
  height: 100%;
}
$primary:#4fbaf6;



@import "./styles/header";


@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// Add your custom colors
$custom-colors: (
  "accent":#1872e4,
);

// Merge your custom colors with Bootstrap's color map
$theme-colors: map-merge($theme-colors, $custom-colors);



.bg-primary-100{
  background-color: hsl(240, 100%, 98%);
}

.text-sm{
  font-size: 1rem !important;
}

h5{
  color: black;
}

td{
  font-size: .875rem;
}

.nav-item,.nav-item span{
  font-size: 0.9rem ;
  // padding: 0.65rem 1.5rem !important;
}

.meeting-review-wrapper{
  min-height: 500px;
  height: 100vh
}
.paging{
    display: flex;
    background-color: white;
    align-items: center;
    width: fit-content;
    padding: 0;
    text-decoration: none;
    border: none;
    padding:3px 16px;
    margin-bottom: 16px;
    border: 1px solid black;
}

thead{
  background-color: #f6f9fc;
}

tr{
  font-size: 14px;
  text-transform: uppercase;
  font-weight: lighter;
  color: #5d6465;
}
td{
  font-size: .9rem;
  text-transform: capitalize;
}

label{
  color: black;
}

.search{
  width: 60%;
  border: 0px;
}
span{
  color: black;
}

.badge.badge-success {
  background: #10b759;
}

.badge.badge-danger {
  background: #ff3f3f;
}

@import 'bootstrap/scss/bootstrap';